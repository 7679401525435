import { SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Button, Input, Popconfirm, Space, Table, Tag } from "antd";
import { get } from "lodash";
import { ACCOUNT_STATUS_MAP_COLOR } from "app/constants/global.constants";
import moment from "moment";
const TableView = (props) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // setTableParams({
    //   pagination,
    //   filters,
    //   ...sorter,
    // });
    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setData([]);
    // }
  };
  const getColumnSearchProps = (dateIndexItem) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dateIndexItem.title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dateIndexItem.index)
          }
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dateIndexItem.index)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      (get(record, dateIndexItem.index, "NA") || "NA")
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dateIndexItem.index ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={get(text, dateIndexItem.index, "NA")}
        />
      ) : (
        get(text, dateIndexItem.index, "NA")
      ),
  });

  const handleCustomRender = (item, colItem) => {
    if (colItem.renderTag) {
      return (
        <Tag color={ACCOUNT_STATUS_MAP_COLOR[get(item, colItem.index, "NA")]}>
          {get(item, colItem.index, "NA")}
        </Tag>
      );
    } else if (colItem.multipleTag) {
      let tags = [];
      let itemList = get(item, colItem.index, []);
      itemList.forEach((tag) => {
        tags.push(<Tag>{get(tag, colItem.tagIndex, "NA")}</Tag>);
      });
      return tags;
    } else if (colItem.renderAction) {
      let actions = [];
      colItem.actions.forEach((action) => {
        if (action.adminOnly && props.userRole?.role_name !== "Admin") return;

        if (action.showOn && !action.showOn(item)) return;
        actions.push(
          !action.popup ? (
            <div
              style={{
                width: 22,
                height: 22,
                borderRadius: "50%",
                backgroundColor: action.color,
                marginRight: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                colItem.handleAction({
                  ...props,
                  eventType: action.event,
                  rowData: item,
                })
              }
            >
              {action.icon}
            </div>
          ) : (
            <Popconfirm
              title={action.popupTitle}
              // description="Are you sure to delete this task?"
              onConfirm={() =>
                colItem.handleAction({
                  ...props,
                  eventType: action.event,
                  rowData: item,
                })
              }
              onCancel={null}
              okText="Yes"
              cancelText="No"
            >
              <div
                style={{
                  width: 22,
                  height: 22,
                  borderRadius: "50%",
                  backgroundColor: action.color,
                  marginRight: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                {action.icon}
              </div>
            </Popconfirm>
          )
        );
      });

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {actions}
        </div>
      );
    } else if (colItem.child) {
      return colItem.child({
        ...item,
        handleSwitch: (userId, status) =>
          props.handleAction({
            ...props,
            eventType: "toggle",
            rowData: { id: userId, NEW_STATUS: status },
          }),
      });
    } else {
      return colItem.date
        ? moment(get(item, colItem.index, "NA")).format("DD/MM/YYY")
        : get(item, colItem.index, "NA");
    }
  };
  const getColumnReady = () => {
    let newColumn = [];
    props.column.forEach((item) => {
      let newColData = {
        ...item,
        render: (data) => handleCustomRender(data, item),
      };
      if (item.search)
        newColData = {
          ...newColData,
          ...getColumnSearchProps(item),
        };
      newColumn.push(newColData);
    });
    return newColumn;
  };
  return (
    <Table
      columns={getColumnReady()}
      dataSource={props.data}
      style={{}}
      loading={props.isLoading}
      onChange={handleTableChange}
      pagination={props.pagination}
    />
  );
};
export default TableView;
