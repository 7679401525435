import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, message, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "./userInvite.scss";
import Papa from "papaparse";
import { apiGet, apiPost } from "app/services/apiService";
import { API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON } from "app/shared/Selector/selector.constants";
import { useEffect } from "react";
import { API_ENDPOINT_USER_CREATE_BULK } from "app/scenes/users/users.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
export default function InviteUser(props) {
  const [roleMap, setRoleMap] = useState(null);
  const [inviteList, setInviteList] = useState([
    {
      id: 1,
      email: "",
      sid: "",
      name: "",
      mobile: "",
    },
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchRoleMap();
  }, []);
  const fetchRoleMap = async () => {
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON + "?service=USER_ROLES"
    );
    let newObjMap = {};
    if (response.status) {
      response.data.forEach((role) => {
        newObjMap[role.role_name] = role.id;
      });
      setRoleMap(newObjMap);
    }
  };

  const addRowInvite = () => {
    const currentId = inviteList[inviteList.length - 1].id;
    setInviteList([
      ...inviteList,
      { email: "", sid: "", name: "", mobile: "", id: currentId + 1 },
    ]);
  };

  const removeRowInvite = (itemId) => {
    setInviteList(inviteList.filter((item) => item.id !== itemId));
  };

  const handleInviteUsers = async () => {
    const response = await apiPost(API_ENDPOINT_USER_CREATE_BULK, {
      users: inviteList,
    });
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const handleInput = (rowId, key, data) => {
    let prevData = JSON.parse(JSON.stringify(inviteList));
    prevData[rowId][key] = data;
    setInviteList(prevData);
  };

  const beforeUpload = (file) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: function (results) {
        addParsedEntry(results.data);
      },
    });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const addParsedEntry = (entries) => {
    let newEntries = [];
    let newId = inviteList.length + 1;
    entries.forEach((element) => {
      if (validateEmail(element[0])) {
        newEntries.push({
          email: element[0],
          sid: element[1],
          name: element[2],
          mobile: element[3],

          id: newId,
        });
        newId = newId + 1;
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: "Invalid CSV file", type: 0 },
        });
        newEntries = [];
        return;
      }
    });
    console.log("new entry", newEntries);
    setInviteList(newEntries);
  };

  console.log("formData:", inviteList);

  return (
    <div className="InviteUser">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleInviteUsers}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="formContainer">
          {inviteList.map((inviteUser, key) => {
            return (
              <div className="form-row" key={key}>
                <div className="input-element">
                  <Form.Item
                    name={"email" + key}
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please check your email",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                    initialValue={inviteUser.email}
                  >
                    <Input
                      placeholder="Email"
                      name={"email" + key}
                      onChange={(e) =>
                        handleInput(key, "email", e.target.value)
                      }
                      defaultValue={inviteUser.email}
                    />
                  </Form.Item>
                </div>
                <div className="input-element">
                  <Form.Item
                    name={"sid" + key}
                    rules={[
                      {
                        required: true,
                        message: "Please check your SID",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                    initialValue={inviteUser.sid}
                  >
                    <Input
                      placeholder="Staff ID"
                      name={"sid" + key}
                      onChange={(e) => handleInput(key, "sid", e.target.value)}
                      defaultValue={inviteUser.sid}
                    />
                  </Form.Item>
                </div>

                <div className="input-element">
                  <Form.Item
                    name={"name" + key}
                    rules={[
                      {
                        required: true,
                        message: "Please check your name",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                    initialValue={inviteUser.name}
                  >
                    <Input
                      placeholder="Full Name"
                      name={"name" + key}
                      onChange={(e) => handleInput(key, "name", e.target.value)}
                      defaultValue={inviteUser.name}
                    />
                  </Form.Item>
                </div>

                <div className="input-element">
                  <Form.Item
                    name={"mobile" + key}
                    rules={[
                      {
                        required: true,
                        min: 10,
                        message: "Please check your mobile",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                    initialValue={inviteUser.mobile}
                  >
                    <Input
                      placeholder="Mobile"
                      name={"mobile" + key}
                      onChange={(e) =>
                        handleInput(key, "mobile", e.target.value)
                      }
                      defaultValue={inviteUser.mobile}
                      maxLength={10}
                    />
                  </Form.Item>
                </div>

                <PlusCircleOutlined onClick={addRowInvite} />
                <DeleteOutlined
                  onClick={() => removeRowInvite(inviteUser.id)}
                />
              </div>
            );
          })}
        </div>
        <Upload
          {...props}
          accept=".csv"
          beforeUpload={beforeUpload}
          customRequest={null}
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        >
          <Typography
            variant="xs"
            color="primary"
            style={{ cursor: "pointer" }}
          >
            Add Bulk User
          </Typography>
        </Upload>

        <div className="action-buttons">
          <Form.Item style={{ width: 240 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              // loading={isLoading}
            >
              Send Invite
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
