import { Form } from "antd";
import { apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { API_ENDPOINT_AUTH_LOGIN } from "../../auth.constants";
import { SAVE_USER_INFO } from "../../redux/auth.action";

import "../../styles/auth.scss";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPasword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogin = async () => {
    setLoading(true);
    const payload = {
      email: email,
      password: password,
    };

    const response = await apiPost(API_ENDPOINT_AUTH_LOGIN, payload);
    if (response.status) {
      localStorage.setItem("token", response.data.accessToken);
      // dispatch({ type: SAVE_USER_INFO, payload: response.data.user_info });
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      navigate("/");
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleLogin}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="input-element" style={{ marginBottom: 24 }}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please check your email",
              },
            ]}
          >
            <Input
              placeholder="Email"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
          </Form.Item>
        </div>
        <div className="input-element">
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
                fontSize: 12,
              },
            ]}
            style={{ fontSize: 12 }}
          >
            <Input
              placeholder="Password"
              type="password"
              label="Password"
              onChange={(e) => setPasword(e.target.value)}
              name="password"
            />
          </Form.Item>

          <Typography
            color="primary"
            variant="body"
            style={{ textAlign: "right", cursor: "pointer" }}
            onClick={() => navigate("/auth/forgot_password")}
          >
            forgot password?
          </Typography>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: "100%" }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
              background="white"
            >
              <p style={{ color: "black" }}>Sign in</p>
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
