import { PrinterOutlined } from "@ant-design/icons";
import { apiGet } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import AppLogo from "assets/images/appLogo.png";
import { wrap } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { API_ENDPOINT_EXAMS_FETCH_SINGLE_RESULT } from "../exams/exams.constants";
import "./OpenResult.scss";

const tableColumn = [
  {
    title: "Subject",
    index: "subject.name",
  },

  {
    title: "Passing Marks",
    index: "subject.passing_marks",
  },
  {
    title: "Scored",
    index: "marks",
  },
  {
    title: "Result",
    index: "result",
    renderTag: true,
  },
];

export default function OpenResult(props) {
  const [selectedCollege, setSelectedCollege] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedExam, setSelectedExam] = useState("");
  const [sid, setSID] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [resultInfo, setResultInfo] = useState(null);
  const [resultFound, setResultFound] = useState(false);
  const dispatch = useDispatch();
  const fetchResult = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_EXAMS_FETCH_SINGLE_RESULT +
        "?" +
        `examId=${selectedExam}&orgId=${selectedCollege}&sId=${sid}`
    );
    if (response.status) {
      processData(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const processData = (data) => {
    data?.exam_participants?.[0]?.exam_results.map((result) => {
      result.result =
        result.marks > result.subject?.passing_marks ? "PASS" : "FAILED";
    });
    console.log("Data", data);
    setResultInfo(data);
    setResultFound(true);
  };

  const printResult = () => {
    var w = window.open();

    w.document.write("<html><head><title></title>");
    w.document.write(
      '<link rel="stylesheet" type="text/css" href="/OpenResult.scss">'
    );
    w.document.write("</head><body >");
    w.document.write(document.getElementById("ResultContainer").innerHTML);
    w.document.write(
      '<script type="text/javascript">addEventListener("load", () => { print(); close(); })</script></body></html>'
    );

    w.document.close();
    w.focus();
  };
  return (
    <div className="OpenResult">
      <div className="logoContainer">
        <img src={AppLogo} alt="appLogo" className="appLogo" />
      </div>

      <Typography style={{ marginBottom: 28 }} variant="h5" color="primary">
        Results Portfolio
      </Typography>
      <div className="MarqueeContainer">
        <div
          className="marquee"
          style={{ color: colorPicker("red"), fontWeight: 600 }}
        >
          ❗Mid-term result for class X-11 is out now
        </div>
      </div>
      {resultFound ? null : (
        <div className="FormContainer">
          <div className="WidgetList">
            <div className="WidgetContainer" style={{ minWidth: 200 }}>
              <Selector
                placeholder="College"
                labelKey="name"
                valueKey="id"
                serviceType="ORGANISATIONS"
                onChange={(data) => setSelectedCollege(data)}
                openAPI
              />
            </div>
            <div className="WidgetContainer" style={{ minWidth: 200 }}>
              <Selector
                placeholder="Class"
                labelKey="name"
                valueKey="id"
                serviceType="CLASSES"
                onChange={(data) => setSelectedClass(data)}
                queryFilter={{
                  key: "userOrganisationId",
                  dataValue: selectedCollege,
                }}
                disabled={!selectedCollege}
                openAPI
              />
            </div>
            <div className="WidgetContainer" style={{ minWidth: 200 }}>
              <Selector
                placeholder="Examination"
                labelKey="name"
                valueKey="id"
                serviceType="EXAMINATION"
                onChange={(data) => setSelectedExam(data)}
                queryFilter={{ key: "classId", dataValue: selectedClass }}
                disabled={!selectedClass}
                openAPI
              />
            </div>
          </div>
          <div className="InputContainers">
            <div className="InputContainer">
              <Input
                placeholder="Student ID (SID)"
                onChange={(e) => setSID(e.target.value)}
                disabled={!selectedClass || !selectedCollege || !selectedExam}
              />
            </div>
            <div className="InputContainer">
              <Button
                size="medium"
                height={40}
                disabled={!selectedClass || !selectedCollege || !selectedExam}
                onClick={fetchResult}
                loading={isLoading}
              >
                Find Result
              </Button>
            </div>
          </div>
        </div>
      )}
      {resultInfo ? (
        <>
          <div className="ResultContainer" id="ResultContainer">
            <div
              className="UserInfoContainer"
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                className="InfoGroup"
                style={{ marginRight: 24, width: "30%" }}
              >
                <Typography variant="caption" color="gray-dark">
                  Name
                </Typography>
                <Typography variant style={{ fontWeight: 600 }}>
                  {resultInfo && resultInfo?.user_profile?.first_name}
                </Typography>
              </div>
              <div
                className="InfoGroup"
                style={{ marginRight: 24, width: "30%" }}
              >
                <Typography variant="caption" color="gray-dark">
                  SID
                </Typography>
                <Typography variant style={{ fontWeight: 600 }}>
                  {resultInfo && resultInfo?.sid}
                </Typography>
              </div>
              <div
                className="InfoGroup"
                style={{ marginRight: 24, width: "30%" }}
              >
                <Typography variant="caption" color="gray-dark">
                  College
                </Typography>
                <Typography variant style={{ fontWeight: 600 }}>
                  SID
                </Typography>
              </div>
              <div
                className="InfoGroup"
                style={{ marginRight: 24, width: "30%" }}
              >
                <Typography variant="caption" color="gray-dark">
                  Year
                </Typography>
                <Typography variant style={{ fontWeight: 600 }}>
                  {moment().format("YYYY")}
                </Typography>
              </div>
            </div>
            <div className="ResultList" style={{ marginTop: 24 }}>
              <TableView
                data={
                  resultInfo && resultInfo?.exam_participants?.[0]?.exam_results
                }
                isLoading={isLoading}
                column={[...tableColumn]}
                pagination={false}
              />

              <table>
                <tbody>
                  <tr>
                    <td>fdsfs</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 8 }}>
            <PrinterOutlined style={{ marginRight: 4 }} />
            <Typography
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={printResult}
            >
              Print the result
            </Typography>
          </div>
          <iframe
            id="ifmcontentstoprint"
            style={{ height: 0, width: 0, position: "absolute" }}
          ></iframe>
        </>
      ) : null}
    </div>
  );
}
